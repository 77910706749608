import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import externalLanguagesAvailable from './assets/locales/external/languages.json';
import languagesAvailable from './assets/locales/languages.json';
var languages = languagesAvailable.languages;
var externalLanguages = externalLanguagesAvailable.languages;
export var LOCAL_STORAGE_LANG_KEY = 'selectedLanguage';
export var getLocales = function getLocales() {
    var languageCodes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var additionLanguages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return (languageCodes.length === 0 ? [].concat(_toConsumableArray(languages), _toConsumableArray(additionLanguages)) : [].concat(_toConsumableArray(languages), _toConsumableArray(additionLanguages)).filter(function (l) {
        return languageCodes.includes(l.language);
    })).map(function (locale) {
        return {
            language: locale.region ? "".concat(locale.language, "-").concat(locale.region).toLocaleLowerCase() : locale.language,
            name: locale.localizedName
        };
    });
};
var DAYJS_LOCALE_MAP = {
    la: 'lo',
    'zh-cn': 'zh-cn'
};
var QUESTIONS_LOCALE_MAP = {
    en: 'en',
    th: 'th',
    vi: 'vn',
    'zh-cn': 'cn'
};
var RTL_LANGUAGES = [].concat(_toConsumableArray(languages), _toConsumableArray(externalLanguages)).filter(function (l) {
    return l.isRtl;
}).map(function (l) {
    return l.language;
});
export var getQuestionsLanguage = function getQuestionsLanguage(language) {
    return QUESTIONS_LOCALE_MAP[language];
};
var setDayjsLocale = function setDayjsLocale(locale) {
    var dayjsLocale = locale;
    if (locale in DAYJS_LOCALE_MAP) {
        dayjsLocale = DAYJS_LOCALE_MAP[locale];
    }
    dayjs.locale(require("dayjs/locale/".concat(dayjsLocale, ".js")));
};
export var isRTLLocale = function isRTLLocale(locale) {
    return RTL_LANGUAGES.includes(locale);
};
var setDocumentAttribute = function setDocumentAttribute(locale) {
    var locales = getLocales([], externalLanguages).map(function (l) {
        return l.language;
    });
    locales.forEach(function (l) {
        return document.documentElement.classList.remove(l);
    });
    locales.forEach(function (l) {
        return document.body.classList.remove(l);
    });
    document.documentElement.classList.add(locale);
    document.body.classList.add(locale);
    document.documentElement.setAttribute('dir', isRTLLocale(locale) ? 'rtl' : 'ltr');
};
export var getLanguage = function getLanguage() {
    var storedLanguage = localStorage.getItem(LOCAL_STORAGE_LANG_KEY);
    var containsZH = /^zh\b/.test(navigator.language.toLowerCase());
    if (storedLanguage) {
        if (storedLanguage === 'en-us') {
            localStorage.setItem(LOCAL_STORAGE_LANG_KEY, 'en');
            return 'en';
        }
        return storedLanguage;
    }
    if (containsZH) {
        return 'zh-cn';
    }
    return 'en';
};
export var setLanguage = function setLanguage(locale, callback) {
    i18n.changeLanguage(locale).then(callback);
    setDayjsLocale(locale);
    localStorage.setItem(LOCAL_STORAGE_LANG_KEY, locale);
    setDocumentAttribute(locale);
};
export var initLocales = function initLocales() {
    setDayjsLocale(getLanguage());
    setDocumentAttribute(getLanguage());
};
export var getTranslatedOrFallback = function getTranslatedOrFallback(key, fallback) {
    return i18n.exists(key) ? i18n.t(key) : fallback;
};
i18n.use(Backend).use(initReactI18next).init({
    initImmediate: false,
    lng: getLanguage(),
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    ns: ['global', 'auth', 'sidebar', 'reports', 'brand_report', 'training_plans', 'qr_code_generator', 'custom_views', 'dynamic_filters', 'joyride', 'dynamic_views', 'registration', 'registration_codes', 'sign_up', 'training_performance', 'insights', 'resources', 'certificates'],
    defaultNS: 'global',
    lowerCaseLng: true,
    load: 'currentOnly',
    returnNull: false,
    interpolation: {
        escapeValue: false
    },
    backend: {
        loadPath: function loadPath(lng, ns) {
            var namespace = ns && typeof ns.toString === 'function' ? ns.toString().trim() : ns;
            if (namespace === 'certificates') {
                return "/static/locales/external/".concat(lng, "/").concat(ns, ".json");
            }
            return "/static/locales/".concat(lng, "/").concat(ns, ".json");
        }
    },
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
});
export default i18n;
