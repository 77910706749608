import { Block, P, theme } from '@quizrr/qcl';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import fileEmptyImg from '../assets/images/file_empty.png';
import { DecorationLink } from '../routes/SignUp/Training/presentations/DecorationLink';
var ErrorFallback = function ErrorFallback() {
    var _useTranslation = useTranslation(), t = _useTranslation.t;
    return React.createElement(Block, {
        display: "flex",
        justifyContent: "center",
        style: {
            height: 'calc(100vh - 100px)'
        }
    }, React.createElement(Block, {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }, React.createElement(Block, {
        style: {
            height: '240px',
            width: '240px'
        }
    }, React.createElement("img", {
        alt: "file-empty",
        src: fileEmptyImg
    })), React.createElement(P, {
        mb: "baseDown2",
        isBold: true
    }, t('global:general_error')), React.createElement(P, {
        color: "gray500"
    }, React.createElement(Trans, {
        i18nKey: 'global:general_error_helptext',
        t: t,
        components: [React.createElement(DecorationLink, {
                key: "back-to-homepage-on-general-errors",
                href: '/app',
                rel: "noreferrer",
                style: {
                    color: theme.colors.blue500
                }
            })]
    }))));
};
export default ErrorFallback;
