import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import { Route, useLocation, Navigate, createBrowserRouter, createRoutesFromElements, Outlet, RouterProvider } from 'react-router-dom';
import { Loading } from '@quizrr/qcl';
import { JoyrideProvider } from '../data/contexts/JoyrideContext';
import { EventTracker } from '../utils/eventTracker';
import CookieConsentBanner from '../presentations/CookieConsentBanner';
import ErrorFallback from '../components/ErrorFallback';
import RequireAuth from './RequireAuth';
var App = loadable(function () {
    return import('./App');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    })
});
var SignIn = loadable(function () {
    return import('./SignIn');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    })
});
var SignUp = loadable(function () {
    return import('./SignUp');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    })
});
var FinishRegister = loadable(function () {
    return import('./FinishRegister');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    })
});
var SignUpSuccess = loadable(function () {
    return import('./SignUp/SelfRegistration/SignUpSuccess');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    })
});
var Training = loadable(function () {
    return import('./Training');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    })
});
var GenerateReport = loadable(function () {
    return import('./GenerateReport');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    })
});
var Certificate = loadable(function () {
    return import('./Certificate');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    })
});
var PlayerTrainings = loadable(function () {
    return import('./PlayerTrainings');
}, {
    fallback: React.createElement(Loading, {
        height: "100%"
    }),
    resolveComponent: function resolveComponent(component) {
        return component.PlayerTrainings;
    }
});
var BaseLayout = function BaseLayout() {
    var location = useLocation();
    useEffect(function () {
        var pageTitle = location.pathname.replaceAll(/\/app\//g, '/');
        EventTracker.pageView({
            pagePath: location.pathname + location.search + location.hash,
            pageTitle: pageTitle.replaceAll(/[0-9a-fA-F]{24}/g, 'ID'),
            pageSearch: location.search,
            pageHash: location.hash
        });
    }, [location.pathname]);
    return React.createElement(JoyrideProvider, null, React.createElement(Outlet, null), React.createElement(CookieConsentBanner, null));
};
var Router = function Router() {
    var router = createBrowserRouter(createRoutesFromElements(React.createElement(Route, {
        element: React.createElement(BaseLayout, null),
        errorElement: React.createElement(ErrorFallback, null)
    }, React.createElement(Route, {
        path: "signin",
        element: React.createElement(SignIn, null)
    }), React.createElement(Route, {
        path: "signup",
        element: React.createElement(SignUp, null)
    }), React.createElement(Route, {
        path: "signup-success",
        element: React.createElement(SignUpSuccess, null)
    }), React.createElement(Route, {
        path: "finish-register",
        element: React.createElement(RequireAuth, null, React.createElement(FinishRegister, null))
    }), React.createElement(Route, {
        path: "app/*",
        element: React.createElement(RequireAuth, null, React.createElement(App, null))
    }), React.createElement(Route, {
        path: "player-trainings",
        element: React.createElement(PlayerTrainings, null)
    }), React.createElement(Route, {
        path: "training",
        element: React.createElement(Training, null)
    }), React.createElement(Route, {
        path: "certificate/*",
        element: React.createElement(Certificate, null)
    }), React.createElement(Route, {
        path: "generate-report/*",
        element: React.createElement(RequireAuth, null, React.createElement(GenerateReport, null))
    }), React.createElement(Route, {
        path: "/*",
        element: React.createElement(Navigate, {
            to: "app"
        })
    }))));
    return React.createElement(RouterProvider, {
        router: router
    });
};
export default Router;
