export var AccountType = function (AccountType) {
    AccountType["BRAND"] = "brand";
    AccountType["TRAINING_FACILITY"] = "training_facility";
    AccountType["ORGANIZATION"] = "organization";
    AccountType["SUPPLIER"] = "supplier";
    return AccountType;
}({});
export var AccountRole = function (AccountRole) {
    AccountRole["OWNER"] = "owner";
    AccountRole["ADMIN"] = "admin";
    AccountRole["USER"] = "user";
    AccountRole["PLAYER"] = "player";
    AccountRole["TEAM_ADMIN"] = "team-admin";
    AccountRole["LOCAL_MANAGER"] = "local_manager";
    AccountRole["LOCAL_TRAINER"] = "local_trainer";
    return AccountRole;
}({});
export var FacilityType = function (FacilityType) {
    FacilityType["ProductionUnit"] = "production_unit";
    FacilityType["LaborAgent"] = "labor_agent";
    FacilityType["TrainingUnit"] = "training_unit";
    FacilityType["RetailStore"] = "retail_store";
    FacilityType["LogisticsHub"] = "logistics_hub";
    FacilityType["ServiceProvider"] = "service_provider";
    return FacilityType;
}({});
