var LEARNING_MODULES_ORDERED = {
    bangladesh: {
        bl: ['executive_summary', 'workplace_policies', 'dialogue_engagement', 'worker_representation', 'nomination_election', 'roles_responsibilities'],
        esr_vf: ['understanding_gender', 'gender_abuse_harassment', 'protection_policies', 'workplace_gender_protection'],
        ohs: ['health_safety', 'fire_building_safety'],
        stitch: ['family_finances'],
        stitch_pilot: ['leadership_skills', 'dialogue_skills', 'personal_health']
    },
    brazil: {
        esr: ['understanding_gender', 'gender_abuse_harassment', 'workplace_gender_policies', 'protection_policies_brazil']
    },
    cambodia: {
        labor_relations_vf: ['the_basics', 'dialogue_engagement', 'freedom_association', 'raising_grievances'],
        resrec: ['employment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    },
    canada: {
        esr: ['budgets'],
        resrec: ['employment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    },
    china: {
        ccrcsr: ['care', 'needs', 'communication', 'distress', 'togetherness'],
        iw: ['introduction', 'employment_practices', 'workplace_policies'],
        wms: ['executive_summary', 'introduction', 'employment', 'wages', 'pay_system']
    },
    global: {
        child_labor_prevention_aurora: ['understanding_child_rights_labor', 'preventing_child_labor', 'remediating_child_labor'],
        child_labour_prevention_remediation: ['understanding_child_rights_labour', 'child_labour_prevention_age_verification', 'child_labour_remediation_underage_workers', 'remediation_young_workers_unauthorized_children'],
        child_labor_prevention_vf: ['introduction', 'childrens_rights', 'child_labor_preventation', 'child_labor_identification_remediation', 'management_of_juvenile_workers'],
        effective_gm: ['understanding_grievance_mechanisms', 'building_grievance_mechanism', 'grievance_process_steps', 'roles_and_responsibilities'],
        effective_gm_demo: ['understanding_grievance_mechanisms'],
        esr: ['understanding_gender', 'gender_abuse_harassment', 'workplace_gender_protection', 'protection_policies_india'],
        grievance_agriculture_ra: ['understanding_grievance', 'grievance_mechanism', 'committees_representation', 'solution_prevention'],
        heawel_apple: ['module_1_nutrition', 'module_2_exercise', 'module_3_sleep', 'module_4_hygiene_firstaid', 'module_5_mentalhealth'],
        her_finance: ['digital_money', 'financial_services', 'financial_planning', 'budgets', 'saving', 'family_finances'],
        iom_toolkit_training: ['iom_training_part_1', 'iom_training_part_2'],
        iom_toolkit_training_la: ['iom_training_part_1', 'iom_training_part_2'],
        labor_relations: ['the_basics', 'collective_bargaining', 'freedom_association', 'representation_election'],
        labor_relations_vf: ['the_basics', 'dialogue_engagement', 'freedom_association', 'raising_grievances'],
        lifeskills_apple_eng_1: ['conversational_english', 'greetings', 'small_talk'],
        lifeskills_apple_phh: ['bacterial_disease', 'soap_sanitizer', 'oral_health', 'menstrual_health'],
        lifeskills_health_safety: ['health_safety'],
        ohs: ['personal_health_safety', 'fire_safety'],
        quizrr_talks_hrdd: ['introduction', 'quizrr_talks_hrdd_step_1', 'quizrr_talks_hrdd_step_2', 'quizrr_talks_hrdd_step_3', 'quizrr_talks_hrdd_step_4', 'quizrr_talks_hrdd_step_5', 'quizrr_talks_hrdd_step_6'],
        quizrr_talks_hrdd_m_0: ['introduction'],
        resrec: ['employment_practices', 'workplace_policies', 'workplace_dialogue'],
        resrec_apple: ['employment_practices', 'workplace_policies', 'workplace_dialogue'],
        resrec_apple_rp: ['employment_practices', 'workplace_policies', 'workplace_dialogue'],
        resrec_apple_log: ['employment_practices', 'workplace_policies', 'workplace_dialogue'],
        resrec_refre_apple: ['employment_practices', 'workplace_policies', 'workplace_dialogue'],
        resrec_aurora: ['social_responsibility', 'employment_practices', 'workplace_policies', 'workplace_dialogue'],
        resrec_plan: ['safe_migration', 'risks_exploitation', 'responsible_recruitment', 'seeking_help'],
        resrec_ua: ['recruitment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention'],
        resrec_vf: ['employment_practices', 'workplace_policies', 'workplace_dialogue'],
        rr: ['workplace_policies', 'health_safety', 'fire_building_safety', 'workplace_dialogue', 'disease_control_prevention'],
        scoc: ['introduction', 'labor_human_rights', 'health_safety', 'environment', 'ethics'],
        scs_amazon: ['introduction', 'module_1_aurora', 'module_2_aurora', 'module_3_aurora', 'conclusions_aurora'],
        sioux_manual_test: ['care', 'needs', 'budgets'],
        stitch_pilot: ['leadership_skills', 'dialogue_skills'],
        wasman_apple: ['solid_waste_management', 'basics_aerobic_composition', 'sustainable_living'],
        we: ['executive_summary', 'dialogue_engagement', 'worker_representation', 'nomination_election', 'roles_responsibilities']
    },
    india: {
        esr: ['understanding_gender', 'gender_abuse_harassment', 'workplace_gender_protection', 'protection_policies_india'],
        resrec: ['employment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    },
    indonesia: {
        esr: ['understanding_gender', 'gender_abuse_harassment', 'workplace_gender_protection', 'protection_policies_indonesia']
    },
    jordan: {
        resrec_ua: ['employment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    },
    malaysia: {
        resrec: ['recruitment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    },
    mauritius: {
        rr: ['workplace_dialogue']
    },
    mexico: {
        esr: ['understanding_gender', 'gender_abuse_harassment', 'workplace_gender_policies', 'protection_policies_mexico'],
        resrec: ['recruitment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention'],
        labor_relations_vf: ['the_basics', 'collective_bargaining', 'freedom_association', 'representation_election']
    },
    myanmar: {
        resrec: ['recruitment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    },
    sweden: {
        esr_demo: ['gender_abuse_harassment'],
        ohs: ['personal_health_safety'],
        promo: ['employment_practices']
    },
    taiwan: {
        resrec: ['recruitment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    },
    thailand: {
        esr: ['understanding_gender', 'gender_abuse_harassment', 'workplace_gender_protection', 'grievance_remediation'],
        move_rr: ['employment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention'],
        resrec: ['employment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention'],
        rr: ['introduction', 'workplace_policies', 'employment_practices', 'workplace_dialogue', 'disease_control_prevention', 'management_practices']
    },
    usa: {
        resrec: ['employment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    },
    vietnam: {
        resrec: ['recruitment_practices', 'workplace_policies', 'workplace_dialogue', 'disease_control_prevention']
    }
};
export var getLearningModulesFromMarketTrainingSeries = function getLearningModulesFromMarketTrainingSeries(market, trainingSeries) {
    var _LEARNING_MODULES_ORD;
    var learningModules = (_LEARNING_MODULES_ORD = LEARNING_MODULES_ORDERED[market]) === null || _LEARNING_MODULES_ORD === void 0 ? void 0 : _LEARNING_MODULES_ORD[trainingSeries];
    if (!learningModules || !Object.keys(learningModules).length) {
        return LEARNING_MODULES_ORDERED.global[trainingSeries] || [];
    }
    return learningModules;
};
