export var MemberTrainingStatus = function (MemberTrainingStatus) {
    MemberTrainingStatus["NOT_STARTED"] = "not_started";
    MemberTrainingStatus["IN_PROGRESS"] = "in_progress";
    MemberTrainingStatus["COMPLETED"] = "completed";
    MemberTrainingStatus["INVITE_PENDING"] = "invite_pending";
    MemberTrainingStatus["REQUEST_PENDING"] = "request_pending";
    MemberTrainingStatus["REVOKED"] = "revoked";
    MemberTrainingStatus["REGISTERED"] = "registered";
    return MemberTrainingStatus;
}({});
export var MAX_INTEGER_NUMBER = 65536;
export var TeamSize = Object.freeze({
    10: '0 - 10 ',
    50: '11 - 50',
    200: '51 - 200',
    500: '201 - 500',
    1000: "501 - ".concat(1000..toLocaleString()),
    5000: "".concat(1001..toLocaleString(), " - ").concat(5000..toLocaleString()),
    10000: "".concat(5001..toLocaleString(), " - ").concat(10000..toLocaleString()),
    30000: "".concat(10001..toLocaleString(), " - ").concat(30000..toLocaleString()),
    50000: "".concat(30001..toLocaleString(), " - ").concat(50000..toLocaleString()),
    65536: "".concat(50000..toLocaleString(), "+")
});
